// htmlToPdf.js
// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import axios from 'axios'
function getPdf (title) {
  html2Canvas(document.querySelector('#pdfDom'), {
    allowTaint: true,
    taintTest: false,
    useCORS: true,
    y: 72, // 对Y轴进行裁切
    // width:1200,
    // height:5000,
    dpi: window.devicePixelRatio * 2, //将分辨率提高到特定的DPI 提高四倍
    scale: 2 //按比例增加分辨率 
  }).then(function (canvas) {
    let contentWidth = canvas.width
    let contentHeight = canvas.height
    let pageHeight = contentWidth / 592.28 * 841.89
    let leftHeight = contentHeight
    let position = 0
    let imgWidth = 595.28
    let imgHeight = 592.28 / contentWidth * contentHeight
    let pageData = canvas.toDataURL('image/jpeg', 1.0)
    let PDF = new JsPDF('', 'pt', 'a4')
    if (leftHeight < pageHeight) {
      PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
    } else {
      while (leftHeight > 0) {
        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        leftHeight -= pageHeight
        position -= 841.89
        if (leftHeight > 0) {
          PDF.addPage()
        }
      }
    }
    PDF.save(title + '.pdf');
    let data = PDF.output('datauristring'); //或者直接到处为blob都行
    let file = dataURLtoFile(data, "打印一下.pdf");
    var fd = new FormData();
    fd.append('file', file);
    // print(data)
    axios.post("http://139.224.32.196:800/prj_huang/uploadpdf/", fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      if (res.rescode == 200) {
        console.log("yes")
      }
    }).catch((err) => { });
    // return PDF
  })
}

function dataURLtoFile (dataurl, filename) {

  var arr = dataurl.split(',');

  var mime = arr[0].match(/:(.*?);/)[1];

  var bstr = atob(arr[1]);

  var n = bstr.length;

  var u8arr = new Uint8Array(n);

  while (n--) {

    u8arr[n] = bstr.charCodeAt(n);

  }

  //转换成file对象

  return new File([u8arr], filename, { type: mime });


}
export default getPdf;