/**
 * 学校查询
 */

import { Service } from '@/utils/Service'

export function queryEnrollBathcInfo (data) {
  return Service({
    url: 'enrollbatch/',
    data: data
  })
}

// 一系列的条件查询
export function queryCollegeByCondition (data) {
  return Service({
    url: 'college/bycondition/',
    data: data
  })
}

// 条件查询专业录取信息
export function queryMajorByCondition (data) {
  return Service({
    url: 'major/bycondition/',
    data: data
  })
}

// 查询某学校所有专业的具体录取信息
export function queryCollegeMajor (data) {
  return Service({
    url: 'college/majorenroll/',
    data: data
  })
}

// 查询某学校录取人数信息和分数信息
export function queryCollegeScorePerson (data) {
  return Service({
    url: 'college/enrollgrades/',
    data: data
  })
}
// 查询专业的选课信息
export function queryCollegeMajorRequirement (data) {
  return Service({
    url: 'college/majorrequirement/',
    data: data
  })
}

export function uploadPdf (data) {
  return Service({
    url: 'uploadpdf/',
    data: data
  })
}
