<template>
  <div class="main-sea" :class="independentPage == false ? 'add-padding' : ''">
    <!-- 输入框搜索 -->
    <div class="name-search">
      <div class="name-input-major name-input">
        <el-input
          v-model="postQueryInfo.collegeName"
          placeholder="请输入院校名称"
        ></el-input>
      </div>
      <div class="name-input-major name-input">
        <el-input
          v-model="postQueryInfo.majorName"
          placeholder="请输入专业名称"
        ></el-input>
      </div>
      <el-button
        class="search-btn"
        type="text"
        @click="queryBynameBtn"
        icon="el-icon-search"
        size="medium"
        >搜索
      </el-button>
    </div>

    <!-- 按标签筛选 -->
    <div class="content-box">
      <div class="course-search">
        <div class="condition-search-course" v-if="independentPage == true">
          <!-- 二选一 -->
          <div class="condition-search-list">
            <div class="label1">首选科目(二选一):</div>
            <div class="choose-tag">
              <el-tag
                class="condition-tag"
                v-for="(item, index) in choose1from2"
                :key="item.name"
                :effect="item.choosed ? 'dark' : 'plain'"
                @click="selectCourseLabel(index, '2')"
                style="margin: 2px 4px"
              >
                {{ item.name }}
              </el-tag>
            </div>
          </div>
          <!-- 四选二 -->
          <div class="condition-search-list">
            <div class="label1">再选科目(四选二):</div>
            <div class="choose-tag">
              <el-tag
                class="condition-tag"
                v-for="(item, index) in choose2from4"
                :key="item.name"
                :effect="item.choosed ? 'dark' : 'plain'"
                @click="selectCourseLabel(index, '4')"
                style="margin: 2px 4px"
              >
                {{ item.name }}
              </el-tag>
            </div>
          </div>
        </div>

        <div class="moni-course">
          <el-button
            class="search-btn"
            type="text"
            v-if="independentPage == true"
            @click="moniXuankeBtn"
            size="medium"
          >
            模拟选科
          </el-button>
        </div>
      </div>
      <div class="condition-search">
        <el-collapse v-model="activeCondition">
          <el-collapse-item name="1">
            <template slot="title">
              <div
                class="condition-title-slot"
                @click.native="packUpConditionSearch"
                :title="activeCondition == '1' ? tipPackUp : tipPackDown"
              >
                <i
                  style="font-size: 3.2em; font-weight: 300"
                  class="el-icon-arrow-down"
                  :class="[
                    activeCondition == '1'
                      ? 'arrowTransformReturn'
                      : 'arrowTransform'
                  ]"
                ></i>
              </div>
            </template>
            <div class="condition-search-list">
              <div class="label2">所在地区:</div>
              <div class="choose-tag">
                <el-tag
                  class="condition-tag"
                  size="small"
                  v-for="(item, index) in areaList"
                  :key="item.name"
                  :effect="item.choosed ? 'dark' : 'plain'"
                  @click="changeChooseLabel(index, 'area')"
                  style="margin: 2px 4px"
                >
                  {{ item.name }}
                </el-tag>
              </div>
            </div>
            <div class="condition-search-list">
              <div class="label2">院校类型:</div>
              <div class="choose-tag">
                <el-tag
                  class="condition-tag"
                  size="small"
                  v-for="(item, index) in typeList"
                  :key="item.name"
                  :effect="item.choosed ? 'dark' : 'plain'"
                  @click="changeChooseLabel(index, 'type')"
                  style="margin: 2px 4px"
                >
                  {{ item.name }}
                </el-tag>
              </div>
            </div>
            <div class="condition-search-list">
              <div class="label2">办学实力:</div>
              <div class="choose-tag">
                <el-tag
                  class="condition-tag"
                  size="small"
                  v-for="(item, index) in strengthList"
                  :key="item.name"
                  :effect="item.choosed ? 'dark' : 'plain'"
                  @click="changeChooseLabel(index, 'strength')"
                  style="margin: 2px 4px"
                >
                  {{ item.name }}
                </el-tag>
              </div>
            </div>
            <div class="condition-search-list">
              <div class="label2">办学特色:</div>
              <div class="choose-tag">
                <el-tag
                  class="condition-tag"
                  size="small"
                  v-for="(item, index) in specialList"
                  :key="item.name"
                  :effect="item.choosed ? 'dark' : 'plain'"
                  @click="changeChooseLabel(index, 'special')"
                  style="margin: 2px 4px"
                >
                  {{ item.name }}
                </el-tag>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- 查询结果 -->
      <div class="result">
        <div class="result-count">
          共找到
          <span style="color: #409eff; padding: 0 5px">
            {{ resInfo.schoolCount }}
          </span>
          个满足要求的专业
        </div>
        <div v-if="resInfo.schoolCount > 0" style="width: 100%">
          <el-table
            :data="resInfo.schoolList"
            style="width: 100%"
            align="center"
          >
            <el-table-column prop="logo" label="" width="80" :key="1">
              <template slot-scope="scope">
                <el-image
                  style="width: 60px; height: 60px"
                  :src="scope.row.logo"
                  fit="fill"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="学校" width="200" :key="3">
              <template slot-scope="scope">
                <div>{{ scope.row.collegeId }} {{ scope.row.collegeName }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="provinceName"
              label="所在地"
              width="200"
              :key="4"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.provinceName }} {{ scope.row.cityName }}
              </template>
            </el-table-column>
            <el-table-column
              prop="majorName"
              label="专业名称"
              width="320"
              :key="2"
            >
            </el-table-column>
            <el-table-column
              prop="courseRequire"
              label="选科要求"
              width="160"
              :key="6"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.courseRequire[1] == 1">化学(可选) </span>
                <span v-if="scope.row.courseRequire[1] == 2">化学(必选) </span>

                <span v-if="scope.row.courseRequire[2] == 1">生物(可选) </span>
                <span v-if="scope.row.courseRequire[2] == 2">生物(必选) </span>

                <span v-if="scope.row.courseRequire[3] == 1">政治(可选) </span>
                <span v-if="scope.row.courseRequire[3] == 2">政治(必选) </span>

                <span v-if="scope.row.courseRequire[5] == 1">地理(可选) </span>
                <span v-if="scope.row.courseRequire[5] == 2">地理(必选) </span>

                <span
                  v-if="scope.row.courseRequire[0] == -1"
                  style="color: #ff0000"
                  >未查询到该专业要求的选科信息</span
                >
                <span
                  v-if="
                    scope.row.courseRequire[1] == 0 &&
                    scope.row.courseRequire[2] == 0 &&
                    scope.row.courseRequire[3] == 0 &&
                    scope.row.courseRequire[5] == 0
                  "
                  style="color: #ff0000"
                  >不限</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="provinceName"
              label="添加专业"
              v-if="independentPage == false"
              width="80"
              :key="7"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  class="search-btn"
                  type="text"
                  @click="addMajorTolist(scope.row)"
                  size="medium"
                >
                  添加
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageIter">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="resInfo.schoolCount"
            >
            </el-pagination>
          </div>
        </div>
        <div v-if="resInfo.schoolCount == 0">
          <el-empty description="未查询到符合条件的高校录取数据"></el-empty>
        </div>
      </div>
    </div>
    <!-- 点击选择的专业列表 -->
    <div class="major-list" v-if="independentPage == false">
      <el-collapse v-model="collapseactiveNames" @change="changeCollapseActive">
        <el-collapse-item name="1">
          <template slot="title">
            <span
              style="
                font-size: 1.4em;
                padding-left: 20px;
                color: #4788fb;
                font-weight: bold;
              "
              >专业选择</span
            >
          </template>
          <div class="result-count" style="padding: 10px 0 0 0">
            您选择了<span style="color: #409eff; padding: 0 5px">{{
              selectMajorList.length
            }}</span
            >专业
          </div>
          <el-table
            :data="selectMajorList"
            stripe
            style="width: 100%"
            :height="320"
            :header-cell-style="{
              color: '#4788fb',
              fontSize: '20px',
              fontWeight: 'blod'
            }"
          >
            <el-table-column prop="collegeName" label="学校"> </el-table-column>
            <el-table-column prop="majorName" label="专业" width="240">
            </el-table-column>
            <el-table-column prop="detail" label="删除">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-delete"
                  type="text"
                  style="color: #4788fb"
                  @click="DeleteOneSchool(scope.$index, scope.row)"
                >
                  删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div
            style="
              padding: 10px;
              padding-bottom: 0;
              display: flex;
              justify-content: right;
            "
          >
            <el-button
              type="primary"
              plain
              @click="submitSelectList()"
              size="medium"
            >
              生成报告
            </el-button>
            <el-button
              type="info"
              plain
              @click="clearSelectList()"
              size="medium"
            >
              清除全部
            </el-button>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { queryCollegeMajorRequirement, uploadPdf } from './../../../api/college'
import packup from './../../../icon/packup.png'
import packdown from './../../../icon/packdown.png'
import getPdf from './../../../utils/htmlToPDF'

export default {
  name: 'majorIndex',
  props: {
    // 判断该页面是否是单独一个还是在选课里面的，需要调整CSS和v-if判断一些存在的内容
    independentPage: {
      type: Boolean,
      default: true
    },
    labchoose1from2: {
      type: String,
      default: ''
    },
    labchoose2from4: {
      type: Array,
      default: []
    }
  },
  data: function () {
    return {
      htmlTitle: '随便打一下',
      packup: packup,
      packdown: packdown,
      tipPackUp: '点击收起',
      tipPackDown: '点击展开',
      activeCondition: '1', //展开条件收索菜单栏
      // 所在地区
      areaList: [
        { name: '四川', choosed: false },
        { name: '北京', choosed: false },
        { name: '广东', choosed: false },
        { name: '上海', choosed: false },
        { name: '海南', choosed: false },
        { name: '青海', choosed: false },
        { name: '西藏', choosed: false },
        { name: '香港', choosed: false },
        { name: '天津', choosed: false },
        { name: '山西', choosed: false },
        { name: '内蒙古', choosed: false },
        { name: '辽宁', choosed: false },
        { name: '吉林', choosed: false },
        { name: '黑龙江', choosed: false },
        { name: '浙江', choosed: false },
        { name: '安徽', choosed: false },
        { name: '福建', choosed: false },
        { name: '江西', choosed: false },
        { name: '山东', choosed: false },
        { name: '河南', choosed: false },
        { name: '湖北', choosed: false },
        { name: '湖南', choosed: false },
        { name: '广西', choosed: false },
        { name: '重庆', choosed: false },
        { name: '江苏', choosed: false },
        { name: '贵州', choosed: false },
        { name: '云南', choosed: false },
        { name: '陕西', choosed: false },
        { name: '甘肃', choosed: false },
        { name: '宁夏', choosed: false },
        { name: '新疆', choosed: false },
        { name: '河北', choosed: false }
      ],
      // 院校类型
      typeList: [
        { name: '师范', choosed: false },
        { name: '工科', choosed: false },
        { name: '医药', choosed: false },
        { name: '财经', choosed: false },
        { name: '语言', choosed: false },
        { name: '综合', choosed: false },
        { name: '农业', choosed: false },
        { name: '体育', choosed: false },
        { name: '政法', choosed: false },
        { name: '林业', choosed: false },
        { name: '艺术', choosed: false },
        { name: '民族', choosed: false },
        { name: '军事', choosed: false }
      ],
      // 实力标签
      strengthList: [
        { name: '985', choosed: false, label: '985' },
        { name: '211', choosed: false, label: '211' },
        { name: '双一流学科高校', choosed: false, label: 'shuangyiliuxueke' },
        { name: '珠峰计划', choosed: false, label: 'zhufengjihua' },
        { name: 'C9', choosed: false, label: 'c9' },
        { name: '卓越大学联盟', choosed: false, label: 'zhuoyuedaxuelianmeng' },
        {
          name: '985工程优势学科创新平台',
          choosed: false,
          label: '985gongchengyoushichuangxinpingtai'
        },
        { name: '部省共建', choosed: false, label: 'bushenggongjian' },
        { name: 'CDIO工程教育', choosed: false, label: 'cdio' },
        { name: '卓越教师', choosed: false, label: 'zhuoyuejiaoshi' },
        { name: '卓越中医', choosed: false, label: 'zhuoyuezhongyi' },
        { name: '卓越农林', choosed: false, label: 'zhuoyuenonglin' },
        { name: '中西部100所', choosed: false, label: 'zhongxibu100suo' },
        { name: '示范高职', choosed: false, label: 'shifangaozhi' },
        { name: '骨干高职', choosed: false, label: 'gugangaozhi' }
      ],
      // 特色标签
      specialList: [
        { name: '华东五校', choosed: false, label: 'huadongwuxiao' },
        { name: '中坚九校', choosed: false, label: 'zhongjian9xiao' },
        { name: '国防七子', choosed: false, label: 'guofang7zi' },
        { name: '四大工学院', choosed: false, label: 'sidagongxueyuan' },
        { name: '两电一邮', choosed: false, label: 'liangdianyiyou' },
        { name: '两财一贸', choosed: false, label: 'liangcaiyimao' },
        { name: '六大财经院校', choosed: false, label: 'liudaocaijing' },
        { name: '九大师范类院校', choosed: false, label: 'jiudashifan' },
        { name: '十大医学院校', choosed: false, label: 'shidayixue' },
        { name: '建筑老八校', choosed: false, label: 'jianzhulao8xiao' },
        { name: '建筑新八校', choosed: false, label: 'jianzhuxin8xiao' },
        { name: '法学五院四系', choosed: false, label: 'faxuewuyuansixi' },
        { name: '六大农林', choosed: false, label: 'liudanonglin' }
      ],
      choose1from2: [
        { name: '物理', choosed: true, label: 'physics_require' },
        { name: '历史', choosed: false, label: 'history_require' }
      ],
      choose2from4: [
        { name: '化学', choosed: true, label: 'chemistry_require' },
        { name: '生物', choosed: true, label: 'biology_require' },
        { name: '政治', choosed: false, label: 'politics_require' },
        { name: '地理', choosed: false, label: 'geography_require' }
      ],
      postQueryInfo: {
        collegeName: '', // 输入的搜索文字--院校名称
        majorName: '', // 输入的搜索文字--专业
        area: [],
        type: [],
        strength: [],
        special: [],
        choose1from2: 'physics_require',
        choose2from4: ['chemistry_require', 'biology_require']
      },

      resInfo: {
        schoolCount: 1,
        schoolPage: null,
        schoolList: []
      },
      currentPage: 1, // 当前第几页
      pageSize: 10, // 当前页展示的结果的数量

      // 点击添加到列表的
      selectMajorList: [],
      collapseactiveNames: []
    }
  },
  created: function () {
    this.resInfo.schoolCount = this.resInfo.schoolList.length
    if (this.independentPage == false) {
      this.postQueryInfo.choose1from2 = this.labchoose1from2
      this.postQueryInfo.choose2from4 = this.labchoose2from4
      console.log('courserequire created postQueryInfo ', this.postQueryInfo)
    }
    this.trigerQueryCollegeMajorRequirement()
  },
  methods: {
    clickGetPdf () {
      getPdf(this.htmlTitle)
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      //columnIndex 表示需要合并的列，多列时用 || 隔开
      if (columnIndex === 0 || columnIndex === 1) {
        const _row = this.filterData(this.resInfo.schoolList, columnIndex).one[
          rowIndex
        ]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    filterData (arr, colIndex) {
      let spanOneArr = []
      let concatOne = 0
      arr.forEach((item, index) => {
        if (index == 0) {
          spanOneArr.push(1)
        } else {
          //class 和classify 分别表示表格数据第一列和第二列对应的参数字段，根据实际参数修改
          if (colIndex == 0) {
            if (item.collegeName === arr[index - 1].collegeName) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          } else {
            if (item.collegeName === arr[index - 1].collegeName) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        }
      })
      return {
        one: spanOneArr
      }
    },
    selectCourseLabel: function (index, tag) {
      if (tag == '2') {
        if (!this.choose1from2[index].choosed) {
          this.choose1from2[index].choosed = true
          if (index == 0) {
            this.choose1from2[1].choosed = false
          } else {
            this.choose1from2[0].choosed = false
          }
          this.postQueryInfo.choose1from2 = this.choose1from2[index].label
          this.trigerQueryCollegeMajorRequirement()
        }
      }
      if (tag == '4') {
        // let postInfoList = this.postQueryInfo.choose2from4;
        let label = this.choose2from4[index].label
        let label_index = this.postQueryInfo.choose2from4.indexOf(label)
        //表示点击的元素已经在列表里面了
        if (label_index >= 0) {
          this.choose2from4[index].choosed = false
          //去掉发送数据中的元素
          this.postQueryInfo.choose2from4.splice(label_index, 1)
        } else {
          if (this.postQueryInfo.choose2from4.length == 2) {
            //已经选择了两个了，去除第一个
            let label_rm = this.postQueryInfo.choose2from4.shift()
            console.log(label_rm)
            for (let i = 0; i < this.choose2from4.length; i++) {
              if (label_rm == this.choose2from4[i].label) {
                this.choose2from4[i].choosed = false
              }
            }
            //添加新进元素
            this.postQueryInfo.choose2from4.push(label)
            this.choose2from4[index].choosed = true
          } else {
            this.postQueryInfo.choose2from4.push(label)
            this.choose2from4[index].choosed = true
          }
        }
        this.trigerQueryCollegeMajorRequirement()
      }
    },
    changeChooseLabel: function (index, tag) {
      // 没有全部，但每个都是可以被选择的
      // 带全部的
      let curList = null
      let postInfoList = null
      switch (tag) {
        case 'area':
          curList = this.areaList
          postInfoList = this.postQueryInfo.area
          break
        case 'type':
          curList = this.typeList
          postInfoList = this.postQueryInfo.type
          break
        case 'special':
          curList = this.specialList
          postInfoList = this.postQueryInfo.special
          break
        case 'strength':
          curList = this.strengthList
          postInfoList = this.postQueryInfo.strength
          break
      }
      curList[index].choosed = !curList[index].choosed
      // 取消选择 -- 将需要发送数据里面该项全部删除
      let noLabel = ['area', 'type']
      if (!curList[index].choosed) {
        if (noLabel.indexOf(tag) >= 0) {
          let label = curList[index].name
          for (let i = 0; i < postInfoList.length; i++) {
            if (label == postInfoList[i]) {
              postInfoList.splice(i, 1)
            }
          }
        } else {
          //如果不是以上两种
          let label = curList[index].label
          for (let i = 0; i < postInfoList.length; i++) {
            if (label == postInfoList[i]) {
              postInfoList.splice(i, 1)
            }
          }
        }
      } else {
        // 选中
        if (noLabel.indexOf(tag) >= 0) {
          let label = curList[index].name
          postInfoList.push(label)
        } else {
          // 将该项给传递到后端
          let label = curList[index].label
          postInfoList.push(label)
        }
      }
      // 条件变了，就直接默认从第一页开始查吧
      this.currentPage = 1
      this.trigerQueryCollegeMajorRequirement()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
      this.changePagePost()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.changePagePost()
    },
    changePagePost: function () {
      this.trigerQueryCollegeMajorRequirement()
    },

    trigerQueryCollegeMajorRequirement: function () {
      queryCollegeMajorRequirement({
        collegeName:
          this.postQueryInfo.collegeName == null
            ? ''
            : this.postQueryInfo.collegeName,
        majorName:
          this.postQueryInfo.majorName == null
            ? ''
            : this.postQueryInfo.majorName,
        area: this.postQueryInfo.area, // 所在省份
        type: this.postQueryInfo.type, // 综合，军事...
        special: this.postQueryInfo.special,
        strength: this.postQueryInfo.strength,
        choose1from2: this.postQueryInfo.choose1from2,
        choose2from4: this.postQueryInfo.choose2from4,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      })
        .then(res => {
          if (res.rescode == 200) {
            this.resInfo.schoolList = res.colleges
            this.resInfo.schoolCount = res.totalCount
          }
        })
        .catch(err => {})
    },
    queryBynameBtn: function () {
      this.currentPage = 1
      this.trigerQueryCollegeMajorRequirement()
    },
    /**
     * 模拟选科的按钮
     */
    moniXuankeBtn: function () {
      this.$router.push({
        path: '/monixuanke'
      })
    },

    /**
     * 将该专业添加到单独列表之中
     */
    addMajorTolist: function (row) {
      if (this.selectMajorList.length > 120) {
        this.$message.error('您最多选择120个专业信息')
        return
      }
      let flag = false
      this.selectMajorList.forEach(element => {
        if (
          row.collegeName == element.collegeName &&
          row.majorName == element.majorName
        ) {
          flag = true
        }
      })
      if (flag == false) {
        this.collapseactiveNames = ['1']
        this.selectMajorList.push(row)
      }
    },
    /**
     * 从列表中删除一个学校
     */
    DeleteOneSchool: function (index, row) {
      console.log(index)
      this.selectMajorList.splice(index, 1)
    },
    // 此时生成报告--页面切换，向父组件传值
    submitSelectList: function () {
      if (this.selectMajorList.length != 0) {
        console.log('submit', this.selectMajorList)
        this.$emit('getXuankeReport', this.selectMajorList)
      } else {
        this.$message.error('您还没有选择专业')
      }
    },
    // 清空所有的内容
    clearSelectList: function () {
      this.selectMajorList.splice(0, this.selectMajorList.length)
    },
    changeCollapseActive (val) {}
  }
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}

/deep/ .el-pagination {
  white-space: pre-wrap;
}

.main-sea {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.content-box {
  margin: 0 10%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.label1 {
  // width: 26%;
  min-width: 80px;
  margin-right: 40px;
  font-size: 1.2rem;
  text-align: left;
  margin-left: 20px;
}

.condition-search {
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;

  .condition-search-header {
    align-items: center;
    width: 100%;
    font-size: 20px;
    font-family: '宋体';
    color: #0f1011;
  }
}

.condition-title-slot {
  width: 100%;
  margin-top: 0px;
  border-bottom: 1px solid #e9ebec;
  height: 50px;
}

.condition-search-list {
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  box-sizing: border-box;

  .label2 {
    width: 10%;
    min-width: 80px;
    font-size: 1.2em;
    text-align: left;
    margin-left: 20px;
  }

  .choose-tag {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    margin-left: -10px;
  }

  .condition-tag {
    font-size: 17px;
  }
}

.result {
  display: flex;
  margin-top: 30px;
  width: 100%;
  justify-content: left;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: white;

  .result-count {
    width: 100%;
    text-align: center;
    padding: 20px;
    padding-left: 0;
  }
}

.pageIter {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

/deep/ .el-tag {
  cursor: pointer;
}

.arrowTransform {
  transition: 0.2s;
  transform-origin: center;
  transform: rotateZ(180deg);
}

.arrowTransformReturn {
  transition: 0.2s;
  transform-origin: center;
  transform: rotateZ(0deg);
}

.course-search {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  align-items: center;

  .condition-search-course {
    display: flex;
    justify-content: left;
    flex-direction: column;
    width: 80%;
  }

  .moni-course {
    margin: 0 60px;

    .search-btn {
      margin: 10px 20px;
      padding: 20px 30px;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 0.2em;
      color: #f5fbff;
      background-color: #f17c67;
      height: 60%;
    }

    .search-btn:hover {
      background-color: #e87b68;
    }
  }
}

// 关于不同页面是否需要padding的问题
.add-padding {
  padding: 0;
}

// 最后点击生成的一个类似购物的列表
.major-list {
  width: 440px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 50px;
  top: 170px;
  background: #fff;
  z-index: 100;
}

.search-btn {
  color: #f5fbff;
  background-color: #5c881a;
  width: 90%;
}

.search-btn:hover {
  background-color: #9ccd53;
}

@media only screen and (min-width: 480px) {
  .name-search {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 80px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;

    .name-input {
      width: 60%;
      max-width: 600px;
      min-width: 200px;
      padding: 20px 40px;
      align-content: center;
      box-sizing: border-box;
      height: 80%;
    }

    .name-input-major {
      width: 40%;
    }

    .search-btn {
      margin-top: 1%;
      margin-left: -20px;
      font-size: 18px;
      width: 8%;
      height: 60%;
    }
  }
}

// 移动端搜索
@media only screen and (max-width: 480px) {
  .name-input-major {
    margin-bottom: 1rem;
  }
  .search-btn {
    width: 80%;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .major-list {
    width: 320px;
    left: 5px;
    top: 100px;
    z-index: 100;
  }
}
</style>
